import React, { FunctionComponent, useEffect, useState } from "react"

const Spaceship: FunctionComponent = () => {
  const [shipRotation, setShipRotation] = useState(0)

  useEffect(() => {
    let offset = 9.5

    if (window.innerWidth < 640) {
      offset = 10.5
    } else if (window.innerWidth < 1280) {
      offset = 10
    }

    async function firePhaser(i: number) {
      // setShipRotation(i * 15);

      const phaser = document.createElement("div")
      const rect = document
        .getElementById("spaceship-gun")
        ?.getBoundingClientRect()

      const phaserHeight = 16.8799
      const phaserWidth = 5.7787

      if (rect) {
        phaser.style.width = `${phaserWidth}px`
        phaser.style.height = `${phaserHeight}px`

        phaser.style.left = `${rect.left + phaserWidth / 2}px`
        phaser.style.top = `${rect.top + window.scrollY}px`
        phaser.style.transform = `rotate(${i * 15}deg) translateY(20px)`

        phaser.classList.add("absolute", "bg-white", "transition-opacity")

        document.getElementById("phaser-container")?.appendChild(phaser)

        const buttonRect = document
          .getElementById("hero-quiz-button")
          ?.getBoundingClientRect()

        console.log("yes")

        if (buttonRect) {
          let j = 1
          const interval = setInterval(() => {
            const phaserRect = phaser.getBoundingClientRect()

            const overlap =
              (phaserRect.right < buttonRect.left ||
                phaserRect.left > buttonRect.right ||
                phaserRect.bottom < buttonRect.top ||
                phaserRect.top > buttonRect.bottom) === false

            if (overlap || j >= 40) {
              clearInterval(interval)

              console.log("HELLO")

              document
                .querySelector("#hero-quiz-button a")
                ?.classList.add("shake-bottom")
              phaser.remove()

              setTimeout(() => {
                document
                  .querySelector("#hero-quiz-button a")
                  ?.classList.remove("shake-bottom")

                firePhaser(i)
              }, 5000)
            }

            phaser.style.transform = `rotate(${i * 15}deg) translateY(-${
              j * 20
            }px)`
            j += 1
          }, 125)
        }
      }
    }

    // Spin in circle
    // for (let i = 0; i < 24; i += 1) {
    //   setTimeout(() => firePhaser(i), i * 500);
    // }

    setTimeout(() => {
      setShipRotation(offset * 15)
    }, 2000)

    setTimeout(() => {
      setTimeout(() => firePhaser(offset), 250)
      setTimeout(() => firePhaser(offset), 500)
      setTimeout(() => firePhaser(offset), 750)
    }, 2500)
  }, [])

  return (
    <div id="spaceship-container">
      <svg
        className="transition-transform"
        fill="none"
        height="79"
        id="spaceship"
        style={{ transform: `rotate(${shipRotation}deg)` }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 73 79"
        width="73"
      >
        <rect
          x="17.043"
          y="50.4294"
          width="38.9302"
          height="22.2024"
          fill="#FFFFFF"
        />
        <rect
          x="11.416"
          y="56.0569"
          width="38.9302"
          height="16.5757"
          fill="#FFFFFF"
        />
        <rect
          x="22.6699"
          y="56.0562"
          width="38.9302"
          height="16.5757"
          fill="#FFFFFF"
        />
        <rect
          x="27.9922"
          y="16.9736"
          width="16.8799"
          height="55.658"
          fill="#FFFFFF"
        />
        <rect
          id="spaceship-gun"
          x="33.6172"
          y="0.398193"
          width="5.7787"
          height="16.8799"
          fill="#FFFFFF"
        />
        <rect
          x="17.043"
          y="33.5493"
          width="5.7787"
          height="16.8799"
          fill="#FFFFFF"
        />
        <rect
          x="50.1934"
          y="33.5493"
          width="5.7787"
          height="16.8799"
          fill="#FFFFFF"
        />
        <rect
          x="66.9219"
          y="44.9548"
          width="5.7787"
          height="22.0503"
          fill="#FFFFFF"
        />
        <rect
          x="0.314453"
          y="44.9548"
          width="5.7787"
          height="22.0503"
          fill="#FFFFFF"
        />
        <rect
          x="0.314453"
          y="67.0051"
          width="5.7787"
          height="11.1012"
          transform="rotate(-90 0.314453 67.0051)"
          fill="#FFFFFF"
        />
        <rect
          x="61.5996"
          y="67.0051"
          width="5.7787"
          height="11.1012"
          transform="rotate(-90 61.5996 67.0051)"
          fill="#FFFFFF"
        />
        <rect
          x="33.6172"
          y="72.6318"
          width="5.7787"
          height="5.62662"
          fill="#FFFFFF"
        />
        <rect
          x="39.2441"
          y="67.0051"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
        <rect
          x="27.8398"
          y="67.0049"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
        <rect
          x="44.8711"
          y="72.6316"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
        <rect
          x="22.3652"
          y="72.6318"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
        <rect
          x="0.314453"
          y="44.9548"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
        <rect
          x="66.9219"
          y="44.9548"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
        <rect
          x="50.1934"
          y="33.5493"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
        <rect
          x="17.043"
          y="33.5493"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
        <rect
          x="33.6172"
          y="43.7383"
          width="5.7787"
          height="11.8615"
          fill="#FF4242"
        />
        <rect
          x="27.8398"
          y="55.5999"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
        <rect
          x="39.3965"
          y="55.5999"
          width="5.7787"
          height="5.62662"
          fill="#FF4242"
        />
      </svg>
    </div>
  )
}

export default Spaceship
