import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import SEO from "../components/SEO"
import Spaceship from "../components/svg/Spaceship"

const resources = ({ data }) => {
  const resources = data.allContentfulResource.edges.map(
    (elem: any) => elem.node
  )

  console.log(resources)
  return (
    <Layout absoluteHeader>
      <SEO
        description="Utilize our library of simple to use gamified resources, each curated to leverage science, gamification, and the latest in AI wellness and habit modification. "
        link="https://mooremomentum.com/resources/"
        title="Free Gamification Resources"
        image="https://images.ctfassets.net/8kplw9flsxgi/1UWjHItTdkTXE2scQvKJip/467a5059aaba6b863134615fd246fd8e/Emmett-Happy-Town-V2.png"
      />
      <div className="overflow-hidden relative">
        <div className="black-gradient-bg pt-24 sm:pt-32 mb-12 md:pt-28 h-lvh">
          <div className="container mx-auto px-4 relative text-white z-10">
            <div className="relative sm:order-1 ">
              <div className="space-y-4 text-link mt-28">
                <h2 className="font-Orbitron text-2xl lg:text-4xl mb-0 mt-12 text-center md:text-left md:pb-0 pb-4">
                  MM Resource ARCADE
                </h2>
                <p className="text-xl md:block hidden">
                  Free Access To Gamified Resources.
                </p>
                <div
                  className="flex items-center relative z-10"
                  id="hero-quiz-button"
                >
                  <StaticImage
                    class="m-auto md:mb-28 mb-4 animate-pulse"
                    alt="logo"
                    formats={["png"]}
                    placeholder="blurred"
                    src="../images/arcade.png"
                  />
                </div>
                <h2 className="font-Orbitron text-2xl lg:text-4xl text-center md:text-left md:hidden block pb-28">
                  Free Access To Gamified Resources.
                </h2>
                {/* <p className="max-w-xl  xl:max-w-3xl">
                  Ask 100 people what it means to be happy, and you'll get 100
                  different answers. But what if there was a unified answer
                  rooted in universal principles that have existed since the
                  beginning of time? In today's fast-paced world, the concept of
                  the 5 Core Areas of Life offers a powerful framework for
                  personal growth and happiness.
                </p>
                <p>
                  Settle in, download a workbook, and make yourself at home!
                </p> */}
              </div>
              <div className="absolute bottom-full w-16 z-10 sm:w-20 md:w-24 lg:rotate-0">
                <Spaceship />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto md:py-10 py-0">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {resources?.map(elem => (
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <GatsbyImage
                alt={
                  elem.image?.description ? elem.image?.description : elem.title
                }
                image={elem.image?.gatsbyImageData}
                className="mb-4"
              />
              <h2 className="text-xl font-semibold mb-2 mt-4">{elem?.title}</h2>
              <p className="text-gray-600 mb-4">{elem?.metaDescription}</p>
              <Link to={`/resource/${elem?.slug}`}>
                {" "}
                <button className="resource-btns">Get</button>
              </Link>
            </div>
          ))}

          {/* Column 2 */}

          {/* Column 3 */}
        </div>
      </div>{" "}
      <div id="phaser-container" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulResource(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          image {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          date
          id
          metaDescription
          slug
          title
        }
      }
    }
  }
`

export default resources
